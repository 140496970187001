import SaveStandardTextController from './save_standard_base_text_controller';

export default class extends SaveStandardTextController {
  static targets = [...SaveStandardTextController.baseTargets, 'material', 'keywords', 'value', 'variant', 'mode_of_removal', 'risk_class'];

  setContent() {
    this.materialTarget.value = this.text.material || '';

    this.keywordsTarget.__vue__.items = this.string2chips(this.text.keywords || '');
    this.variantTarget.__vue__.items = this.string2chips(this.text.variant || '');
    const tempTarget = this.mode_of_removalTargets
      .find(({ value }) => value === this.text.mode_of_removal);
    if (tempTarget !== undefined) {
      tempTarget.checked = true;
    }
    this.risk_classTargets.find(({ value }) => value === this.text.risk_class).checked = true;

    this.valueTarget.value = this.text.value || '';
    initTinymce(this.valueTarget);
    window.tinyMCE.editors[this.valueTarget.id].setContent(this.text.value || '');
  }

  string2chips(string) { // eslint-disable-line
    if (string[0] === '[') {
      return JSON.parse(string);
    }
    return (string || '').split(/\s*,\s*/).filter(Boolean); // eslint-disable-line
  }
}
