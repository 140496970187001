import FilterStandardBaseTextController from './filter_standard_base_text_controller';
import match from '../utils/match';

export default class extends FilterStandardBaseTextController {
  initialize() {
    this.textsIdentifier = 'texts';
    this.query = '';
    this.type = this.identifier.replace(/filter-standard-|-text/g, '');
    this.rowIdentifier = `row-${this.type}`;
    this.filters = [];

    this.afterFullLoad(() => {
      this.all = JSON.parse(this.data.get(this.textsIdentifier));
      this._setRow(this.rowsTarget.firstElementChild);
    });
    this.addFilter(this.materialFilter);
    this.addFilter(this.modeOfRemovalFilter);
  }

  materialFilter(standardSourceText) {
    if (!this.row) return true;
    const sourceInstance = this.rowController && this.rowController.item;
    if (!sourceInstance || !sourceInstance.material) return false;

    const material = sourceInstance.material.toLowerCase();
    const textMaterial = standardSourceText.material.toLowerCase();
    return match(material, textMaterial);
  }

  modeOfRemovalFilter(standardSourceText) {
    if (!this.row) return true;
    const sourceInstance = this.rowController && this.rowController.item;
    if (!sourceInstance || !sourceInstance.mode_of_removal) return false;

    return sourceInstance.mode_of_removal === standardSourceText.mode_of_removal;
  }
}
