import Controller from './base_controller';
import match from '../utils/match';

export default class extends Controller {
  static targets = ['all', 'query'];

  initialize() {
    this.toggledOpen = false;
    this.afterFullLoad(() => {
      this.toggleLinks = this.element.getElementsByClassName('togglelink');
    });
  }

  filter() {
    if (!this.hasQueryTarget && !this.queryTarget.value) return;

    if (this.toggledOpen === false) {
      for (let link of this.toggleLinks) {
      // console.log(this.toggleLinks);
      // this.toggleLinks.each((link) => {
        if (!link.getElementsByClassName("glyphicon-minus-sign").length > 0) {
          link.click();
        }
      // });
      };
      this.toggledOpen = true;
    }

    // this.allTargets.each((target) => {
    for (let target of this.allTargets) {
      if (match(target.dataset.value, this.queryTarget.value)) {
        target.style.display = 'block';
      } else {
        target.style.display = 'none';
      }
    }
    // });
  }
}
