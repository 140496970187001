import Controller from './base_controller';
// import 'scripts/plugins/jquery.sticky.js';

export default class extends Controller {
  static targets = ['sticky'];

  initialize() {
    this.target = this.stickyTarget;

    this.afterFullLoad(() => {
      $(this.stickyTarget).sticky({
        id: $(this.stickyTarget).attr('id'),
        topSpacing: 0,
        wrapperClassName: 'sticky-wrapper col-md-3',
      });
    });
  }
}
